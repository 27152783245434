import React from 'react'
import ReportLink from './ReportLink'

const reportLinksData = [
  {
    reportLinkId: 1,
    reportLinkTitle: 'Today',
    reportLinkNames: [
      'Drilling BI Report'
    ]
  },
  {
    reportLinkId: 2,
    reportLinkTitle: 'Previous 7 days',
    reportLinkNames: [
      'Drilling BI Report',
      'ADA AI Geothermal Report',
    ]
  },
  {
    reportLinkId: 3,
    reportLinkTitle: 'Previous 30 days',
    reportLinkNames: [
      'Drilling BI Report',
      'ADA AI Geothermal Report',
      'Production Analytics Report',
      'B4ECarbon Report'
    ]
  }
]

const ReportLinks = () => {
  return (
    <div className='report-link-container'>
      {reportLinksData.map(reportLink => <ReportLink key={reportLink?.reportLinkId} reportLinkData={reportLink} />)}
    </div>
  )
}

export default ReportLinks