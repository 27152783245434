import React from 'react'

const CustomIcon = ({ src = '', alt = '', size = 24, fixedSizeCustomImage = false }) => {
    const customClass = fixedSizeCustomImage ? 'fixed-custom-img' : ''
    return (
        <div className={`custom-icon-container ${customClass}`}>
            <img src={src} alt={alt} height={size} width={size} />
        </div>
    )
}

export default CustomIcon