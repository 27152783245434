import React from 'react';
import { Button } from 'antd';
import EnovateLogo from '../assets/icon/logo-enovate.png';
import B4ELogo from '../assets/icon/logo-b4e.png';
import CustomIcon from '../components/CustomIcon';
import UserProfile from '../components/UserProfile';

const navbarLinks = [{
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Drilling',
    navBarLinkAltText: 'Logo for ADA AI Drilling'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'New Energy',
    navBarLinkAltText: 'Logo for ADA AI New Energy'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Production',
    navBarLinkAltText: 'Logo for ADA AI Production'
}, {
    navBarLinkLogo: B4ELogo,
    navBarLinkText: 'B4E Carbon',
    navBarLinkAltText: 'Logo for B4E Carbon'
}
]


const Navbar = () => {
    return (
        <div className='nav-bar flex flex-row justify-between items-center px-6 py-4'>
            <div className='nav-bar-links'>
                {navbarLinks.map(navbarLink => (
                    <Button
                        key={navbarLink.navBarLinkText}
                        className='mx-2 text-sm font-bold p-4'
                        size='middle'
                        shape='round'
                        icon={<CustomIcon src={navbarLink.navBarLinkLogo} alt={navbarLink.navBarLinkAltText} />}
                    >
                        <span>
                            {navbarLink.navBarLinkBrandName ?
                                <span className='pr-1'>
                                    {navbarLink.navBarLinkBrandName}
                                    <sup className='-top-2'>TM</sup>
                                </span> : ''}
                            {navbarLink.navBarLinkText}
                            {!navbarLink.navBarLinkBrandName ? <sup className='-top-2'>TM</sup> : ''}
                        </span>
                    </Button>
                ))}

            </div>
            <div className='user-profile-container'>
                <UserProfile />
            </div>

        </div>
    )
}

export default Navbar