import React, { useEffect, useContext, useRef } from 'react';
import { ChatContext } from '../context/chat/ChatContext';
import ChatMessage from '../components/chat/ChatMessage';

const Messages = () => {
    const { messages } = useContext(ChatContext);
    const messageContainerRef = useRef(null);

    useEffect(() => {
        messageContainerRef?.current?.lastElementChild?.scrollIntoView()
    }, [messages]);

    return (
        <div className='messages-scroll-section flex flex-col h-full bg-white w-95p m-4 p-4 mb-0 overflow-y-auto'>
            <div className='messages-container w-60p mx-auto' ref={messageContainerRef}>
                {messages?.map((message, index) => (
                    <ChatMessage
                        key={`${message}-${index}`}
                        role={message?.role}
                        content={message?.content}
                        citations={message?.citations}
                    />
                ))}
            </div>
        </div>
    )
}

export default Messages