import React, { useState } from 'react';
import { message } from 'antd';
import { ChatContext } from './ChatContext';
import { config } from '../../config/config';

const errorStatusCodes = [400, 401, 404, 500, 504]

export const ChatProvider = ({ children }) => {
    const savedMessages = JSON.parse(localStorage.getItem('messages'));
    const [messages, setMessages] = useState(savedMessages || []);
    const updateChatMessages = async (userChatMessage) => {
        setMessages(prevMessages => (
            [
                ...prevMessages,
                {
                    role: 'user',
                    content: userChatMessage
                }
            ]
        ))

        const options = {
            method: "POST",
            headers: {
                "api-key": config.AZURE_OPENAI_API_KEY,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                model: config.CHAT_COMPLETIONS_DEPLOYMENT_NAME,
                messages: [{ role: 'user', content: userChatMessage }],
                data_sources: [
                    {
                        type: "azure_search",
                        parameters: {
                            endpoint: config.AZURE_SEARCH_ENDPOINT,
                            index_name: config.AZURE_SEARCH_INDEX,
                            authentication: { type: "api_key", key: config.AZURE_SEARCH_API_KEY },
                            query_type: "vector_semantic_hybrid",
                            embedding_dependency: { type: "deployment_name", deployment_name: config.AZURE_OPENAI_EMBEDDING_DEPLOYMENT },
                            semantic_configuration: "mySemanticConfig"
                        }
                    }
                ]
            })
        }

        try {
            const response = await fetch(`${config.AZURE_OPENAI_ENDPOINT}/openai/deployments/${config.CHAT_COMPLETIONS_DEPLOYMENT_NAME}/chat/completions?api-version=2024-02-01`, options);
            if (errorStatusCodes.includes(response.status)) {
                // setMessages(prevMessages => prevMessages.slice(0, -1));
                throw new Error(" Something went wrong, Please try again later! ")
            }
            const data = await response.json();
            const serverResponseMessage = data?.choices?.[0]?.message;
            if (serverResponseMessage) {
                setMessages(prevMessages => (
                    [
                        ...prevMessages,
                        {
                            role: serverResponseMessage?.role,
                            content: serverResponseMessage?.content,
                            citations: serverResponseMessage?.context?.citations
                        }
                    ]
                ))
            }

        } catch (err) {
            message.error(err.message);
        }
    }

    return (
        <ChatContext.Provider value={{
            messages,
            updateChatMessages
        }}>
            {children}
        </ChatContext.Provider>
    )
}
