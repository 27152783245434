import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Layout from '../layout/Layout';
import Dashboard from '../pages/Dashboard';
import Messages from '../pages/Messages';
import { ChatProvider } from '../context/chat/ChatProvider';


const AppRoutes = () => {
    return (
        <ChatProvider>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path='/messages' element={<Messages />} />
                </Route>
            </Routes>
        </ChatProvider>
    )
}

export default AppRoutes