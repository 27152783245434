import React, { useState, useContext } from 'react';
import { Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChatContext } from '../../context/chat/ChatContext';


const Chat = () => {
    const [value, setValue] = useState('');
    const [inputStatus, setInputStatus] = useState('')
    const { updateChatMessages } = useContext(ChatContext)
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async () => {
        const chatMessage = value;
        if (!chatMessage || chatMessage?.trim === '') {
            setInputStatus('error');
            return;
        }
        updateChatMessages(chatMessage);
        setValue('');
        setInputStatus('')
        if (location.pathname !== '/messages') {
            navigate('/messages')
        }

    }

    const updateInputValue = (e) => {
        if (!e.target.value || e.target.value?.trim() === '') {
            setInputStatus('error')
            setValue('')
            return;
        }
        setInputStatus('');
        setValue(e.target.value)
    }

    return (
        <div className='chat-container flex flex-col  w-60p'>
            <Input
                className='rounded-full'
                size="large"
                placeholder={inputStatus === 'error' ? "Please enter a message" : "Send message"}
                value={value}
                status={inputStatus}
                onChange={updateInputValue}
                onPressEnter={handleSubmit}
                // prefix={(
                //     <FileUploader />
                // )}
                suffix={<SendOutlined className='-rotate-45' onClick={handleSubmit}
                />} />
            <div className='disclaimer-message-container'>
                <p className='disclaimer-message px-3 py-1 text-xs text-gray-400 text-center'>
                    <InfoCircleOutlined className='mx-1' />
                    This is AI-Generated Content
                </p>
            </div>
        </div>
    )
}

export default Chat