import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { ChatContext } from './ChatContext';
import { config } from '../../config/config';
import { useSelector } from 'react-redux';
import { ENOVATE_SUPPORT_EMAIL } from '../../constants';
import { createChatCompletion } from '../../services/api/chatAPI';
import pRetry from 'p-retry';


const isValidJson = (jsonString) => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
}

export const ChatProvider = ({ children }) => {
    const savedMessages = JSON.parse(localStorage.getItem('messages')) || [];
    const { userData } = useSelector(state => state.configAuth);
    const loggedInUserId = userData?.userId;
    const userMessages = !Array.isArray(savedMessages) ? savedMessages?.[loggedInUserId] : savedMessages;
    const [messages, setMessages] = useState(userMessages || []);
    const [isGeneratingMessage, setIsGeneratingMessage] = useState(false);

    useEffect(() => {
        const msgs = !Array.isArray(savedMessages) ? (savedMessages?.[loggedInUserId] ? savedMessages?.[loggedInUserId] : []) : savedMessages;
        setMessages(msgs)
    }, [userData?.userId])
    const updateChatMessages = async (userChatMessage) => {
        setMessages(prevMessages => (
            [
                ...prevMessages,
                {
                    role: 'user',
                    content: userChatMessage
                }
            ]
        ))

        try {
            setIsGeneratingMessage(true);
            const response = await pRetry(() => createChatCompletion(userChatMessage), {
                retries: 5,
                minTimeout: 4000,
                maxTimeout: 60000,
                factor: 2
            })
            setIsGeneratingMessage(false);
            let streamingText = '';
            const reader = response?.body?.getReader();
            while (true) {
                const { done, value } = await reader?.read();
                if (done) break;

                const decodedChunk = new TextDecoder().decode(value);
                streamingText += decodedChunk;
                const lines = streamingText.split("\n")?.filter(text => text !== '');
                setMessages(prevMessages => {
                    const lastElement = prevMessages.at(-1);
                    if (lastElement?.role !== 'user') {
                        return prevMessages.slice(0, -1)
                    }
                    return prevMessages
                })

                for (const line of lines) {

                    if (line?.startsWith('data: ') && !line?.endsWith('[DONE]')) {
                        const dataString = line?.replace(/^data: /, "").trim();
                        if (isValidJson(dataString)) {
                            const data = JSON.parse(dataString);
                            const currentChunk = data?.choices?.[0]?.delta;
                            const currentContent = currentChunk?.content || '';
                            const currentCitations = currentChunk?.context?.citations;
                            //setMessages(prev => [...prev, { role: 'assistant', content: currentResponse }]);
                            setMessages(prevMessages => {
                                const prevChunk = prevMessages?.find(msg => msg?.id === data?.id)
                                if (!prevChunk) {
                                    return [
                                        ...prevMessages,
                                        {
                                            id: data.id,
                                            role: currentChunk?.role,
                                            citations: currentCitations,
                                            content: currentContent,
                                            streamingMessage: true
                                        }
                                    ]
                                } else {
                                    return prevMessages?.map(prevMessage => {
                                        if (prevMessage?.id === data?.id) {
                                            return {
                                                ...prevMessage,
                                                content: prevMessage.content + currentContent,
                                            }
                                        }
                                        return {
                                            ...prevMessage,
                                            streamingMessage: false
                                        }
                                    })
                                }
                            })
                        }
                    } if (line?.endsWith('[DONE]')) {
                        setMessages(prevMessages => prevMessages.map(msg => ({
                            ...msg,
                            streamingMessage: false
                        })))
                    }
                }
            }

        } catch (err) {
            const errorConfig = {
                content: <div className='text-left'>
                    <p>{err.message}</p>
                    <p>If the error persists, please contact
                        <a className='mx-2 text-blue-500 hover:text-blue-400' href={`mailto:${ENOVATE_SUPPORT_EMAIL}`}>{ENOVATE_SUPPORT_EMAIL}</a>
                    </p>
                </div>,
                duration: 10,
                className: 'chat-error'
            }
            message.error(errorConfig);
        } finally {
            setIsGeneratingMessage(false)
        }
    }

    return (
        <ChatContext.Provider value={{
            messages,
            updateChatMessages,
            isGeneratingMessage
        }}>
            {children}
        </ChatContext.Provider>
    )
}
