import './App.css';
import AppRoutes from './routes/AppRoutes';

function App() {

  return (
    <>
      <AppRoutes></AppRoutes>
    </>
  );
}

export default App;
