import React from 'react'

const ReportLink = ({ reportLinkData }) => {
    return (
        <div className='report-link mt-4 pb-4'>
            <h3 className='report-link-header'>{reportLinkData?.reportLinkTitle}</h3>
            {reportLinkData?.reportLinkNames?.map(reportLinkName =>
            (<span key={reportLinkName} className='report-link-name block text-gray-400'>
                {reportLinkName}
            </span>))}
        </div>
    )
}

export default ReportLink