import React from 'react';
import EnovateLogo from '../../assets/icon/logo-enovate-black.png';
import CustomIcon from '../../components/CustomIcon';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ChatMessage = ({ role, content, citations = [] }) => {
    const roleSpecificClassNames = role === 'user' ? 'justify-end ' : ''
    const chatContentClassNames = role === 'user' ? 'bg-pc-maroon px-4 py-2 rounded-full' : ''
    return (
        <div className='chat-message-container my-8'>
            <div className={`chat-message flex flex-row items-start ${roleSpecificClassNames}`}>
                {role === 'assistant' ? <div className='chat-icon-assistant mr-4'>
                    <CustomIcon size={32} src={EnovateLogo} alt="Small Logo for Enovate AI" fixedSizeCustomImage />
                </div> :
                    ''}
                <div className={`chat-content ${chatContentClassNames} flex flex-col`}>
                    <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
                    {citations.length > 0 && (
                        <div className='citations-container'>
                            <ul lassName='citations-list'>
                                {citations.map((citation, index) => (
                                    <li key={index} className='citation my-3'>
                                        <strong>{citation.title || 'Untitled'}</strong>: <Markdown remarkPlugins={[remarkGfm]}>{citation.content}</Markdown>
                                        {citation.url && <a href={citation.url} target="_blank" rel="noopener noreferrer">View Source</a>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {role === 'user' ? <div className='chat-icon-user ml-4'>
                    <Avatar size={32} icon={<UserOutlined />} />
                </div> :
                    ''}
            </div>

        </div>
    )
}

export default ChatMessage