import React from 'react';
import Chat from '../components/chat/Chat';

const Footer = () => {
    return (
        <div className='footer-container flex flex-row items-center justify-center'>
            <Chat />
        </div>
    )
}

export default Footer